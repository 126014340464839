// tslint:disable:max-line-length
import { GetStaticProps } from 'next';
import { PricingFeatures } from 'sailor-ui/pricing/Features';
import { GetPublishableChecklists, useGetPublishableChecklists } from 'services';
import {
  FeatureRelationResponseCollection,
  GetPricingPage,
  GetPricingPlans,
  GetTheboatappHomepage,
  PricingPage,
  TheboatappHomepage,
  useGetPricingPage,
  useGetPricingPlans,
  useGetTheboatappHomepage,
} from 'services/graphql/cms';
import { queryClient } from 'services/queryClient';
import AppShowcase from '../components/Homepage/AppShowcase';
import Available from '../components/Homepage/Available';
import Cards from '../components/Homepage/Cards';
import Checklist from '../components/Homepage/Checklist';
import Community from '../components/Homepage/Community';
import Enjoy from '../components/Homepage/Enjoy';
import Jumbotron from '../components/Homepage/Jumbotron';
import Layout from '../components/Layout';
import { cmsGraphQLClient, graphQLClient } from '../graphQLClient';

type Props = {
  pricingPageFeatures?: PricingPage;
  publicChecklistsData?: NonNullable<GetPublishableChecklists['publishableChecklists']>['data'];
  plans: NonNullable<GetPricingPlans['plans']>['data'];
  homePageData: TheboatappHomepage;
};

const Home = ({ pricingPageFeatures, publicChecklistsData, plans, homePageData }: Props) => {
  const metaData = {
    title: 'Boat / Yacht Management and Maintenance APP',
    description: `The Ultimate Boat/Yacht Management and Maintenance APP. 
      Intuitive Interface providing separate modules, once for each needed function. Discover all the Modules.`,
    keyword: ['boat management and maintenance app', 'yacht management and maintenance app'],
  };

  return (
    <Layout meta={metaData}>
      <main>
        <Jumbotron pageCover={homePageData?.pageCover} />
        <Available availableOn={homePageData?.availableOn} />
        <Enjoy imagesGridWithVideo={homePageData?.imagesGridWithVideo} />
        {pricingPageFeatures ? (
          <PricingFeatures
            isHome
            features={pricingPageFeatures.features as FeatureRelationResponseCollection}
            carouselData={homePageData?.featuresCarousel}
          />
        ) : null}
        <Checklist publicChecklistsData={publicChecklistsData} carouselData={homePageData?.checklistCarousel} />
        <Cards
          plans={plans}
          features={pricingPageFeatures.features as FeatureRelationResponseCollection}
          cardsData={homePageData?.pricingCards}
        />
        <AppShowcase showCaseData={homePageData?.showcase} />
        <Community communityData={homePageData?.joinUs} />
      </main>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const variables = {};
  const plansVariables = {};

  const fetchedPage = await queryClient.ensureQueryData<GetPricingPage>({
    queryKey: ['getPricingPage', variables],
    queryFn: useGetPricingPage.fetcher(cmsGraphQLClient, variables),
  });

  const pricingPlans = await queryClient.ensureQueryData<GetPricingPlans>({
    queryKey: ['getPricingPlans', plansVariables],
    queryFn: useGetPricingPlans.fetcher(cmsGraphQLClient, plansVariables),
  });

  const homePageData = await queryClient.ensureQueryData<GetTheboatappHomepage>({
    queryKey: ['useGetTheboatappHomepage'],
    queryFn: useGetTheboatappHomepage.fetcher(cmsGraphQLClient),
  });

  const publicChecklistData = await useGetPublishableChecklists.fetcher(graphQLClient, { first: 10 })();

  return {
    props: {
      pricingPageFeatures: fetchedPage.pricingPage.data.attributes || null,
      publicChecklistsData: publicChecklistData?.publishableChecklists?.data,
      homePageData: homePageData?.theboatappHomepage?.data?.attributes,
      plans: pricingPlans?.plans?.data,
    },
  };
};

export default Home;
